import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {
  initialize() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300);
  }

  submit() {
    this.element.requestSubmit();
  }

  debouncedSubmit() {
    this.submit();
  }

  search() {
    this.formInputs.forEach((el) => {
      if (el.value === "") el.setAttribute("disabled", "disabled");
    });
    return true;
  }

  onSubmitEnd() {
    this.formInputs.forEach((el) => {
      if (el.value === "") el.removeAttribute("disabled");
    });
  }

  get formInputs() {
    return Array.from(this.element.elements);
  }
}
