import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-top"
export default class extends Controller {
  // TODO: remove
  connect() {
    this.scrollTop();
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: "auto" });
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
  }
}
