import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

// Connects to data-controller="slim-select"
export default class extends Controller {
  static values = {
    allowClear: { type: Boolean, default: false },
    allowSearch: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
  };

  connect() {
    let options = { openPosition: "down" };

    options.showSearch = this.allowSearchValue;
    options.searchText = "Nie znaleziono";
    options.searchPlaceholder = "Szukaj";
    options.allowDeselect = this.allowClearValue;

    if (this.placeholderValue !== "") {
      options.placeholderText = this.placeholderValue;
      this.element.options[0].dataset.placeholder = "true";
    }

    this.slimselect = new SlimSelect({
      select: this.element,
      settings: options,
    });
  }

  disconnect() {
    this.slimselect.destroy();
  }
}
