import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "wrapper", "body"];

  connect() {
    enter(this.wrapperTarget);
    enter(this.bodyTarget);
  }

  close() {
    this.element.parentElement.removeAttribute("src");

    leave(this.wrapperTarget);
    leave(this.bodyTarget).then(() => {
      this.modalTarget.remove();
    });
  }

  closeWithKeyboard(e) {
    if (e.code === "Escape") {
      this.close();
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close();
    }
  };
}
