import { Controller } from "@hotwired/stimulus";
import { enter, leave, toggle } from "el-transition";

export default class extends Controller {
  static targets = ["toggleable"];

  openMenu() {
    enter(this.toggleableTarget);
  }

  closeMenu() {
    leave(this.toggleableTarget);
  }

  toggleMenu() {
    toggle(this.toggleableTarget);
  }
}
