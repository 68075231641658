import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  clear(event) {
    const element = document.getElementById(event.params.element);

    if (element.multiple) {
      element.value = "[]";
    } else {
      element.value = "";
    }

    let eventType;
    if (["select-one", "select-multiple"].includes(element.type)) {
      eventType = "change";
    } else {
      eventType = "input";
    }

    element.dispatchEvent(new Event(eventType, { bubbles: true }));
  }
}
