/* eslint-disable no-undef */
Turbo.setConfirmMethod((message) => {
  const dialog = document.getElementById("turbo-confirm");

  dialog.querySelector("h3.title").textContent = message;
  dialog.classList.remove("hidden");
  dialog.showModal();

  return new Promise((resolve) => {
    dialog.addEventListener(
      "close",
      () => {
        dialog.classList.add("hidden");
        resolve(dialog.returnValue === "confirm");
      },
      { once: true },
    );
  });
});
