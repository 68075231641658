import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="alert"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.show();
    }, 100);

    setTimeout(() => {
      this.close();
    }, 4000);
  }

  close() {
    leave(this.element).then(() => {
      this.element.remove();
    });
  }

  show() {
    enter(this.element);
  }
}
