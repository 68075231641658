import { Controller } from "@hotwired/stimulus";
import AirDatepicker from "air-datepicker";
import localePl from "air-datepicker/locale/pl";

export default class extends Controller {
  static values = {
    timepicker: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    autoClose: { type: Boolean, default: true },
  };

  connect() {
    const element = this.element;
    const options = {
      locale: localePl,
      timeFormat: "HH:mm",
      inline: this.inlineValue,
      autoClose: this.autoCloseValue,
      onSelect() {
        element.dispatchEvent(new Event("input", { bubbles: true }));
      },
    };

    if (element.value !== "") {
      options.selectedDates = [element.value];
    }

    if (this.timepickerValue) {
      options.timepicker = true;
      options.minutesStep = 15;
    }

    new AirDatepicker(element, options);
  }
}
