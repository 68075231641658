import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["form"];
  static values = { url: String };

  reload() {
    const data = new FormData(this.formTarget);
    const queryString = new URLSearchParams(data).toString();

    get(`${this.urlValue}?${queryString}`, {
      responseKind: "turbo-stream",
    });
  }
}
